import { z } from "zod";
import * as enums from "./enums";
export interface MESSAGE_STRUCTURE {
  id: string;
}

const Z_InputTypes = z.enum([
  "staticDropDownListSingleSelect",
  "staticDropDownListMultiSelect",
  "number",
  "text",
  "slider",
]);

export type InputTypes = keyof typeof enums.INPUT_TYPES;

const Z_InputSettings = z.object({
  icon: z.string().optional(),
  iconType: z.string().optional(),
  inputPlaceholder: z.string().optional(),
  inputType: Z_InputTypes,
  mandatory: z.boolean(),
  visible: z.boolean(),
  defaultValue: z
    .union([z.string(), z.number(), z.array(z.string())])
    .optional(),
});

const Z_ConfigInductionQuestions = z.object({
  EntityHierarchy: z.string(),
  questionId: z.string(),
  sortIndex: z.number(),
  label: z.string(),
  disabled: z.boolean(),
  deleted: z.boolean(),
  inputSettings: Z_InputSettings,
  staticDropDownListItems: z.array(
    z.object({
      correct: z.boolean(),
      dataKey: z.string(),
      text: z.string(),
    })
  ),
});

export type ConfigInductionQuestions = z.infer<
  typeof Z_ConfigInductionQuestions
>;

const Z_InductionContent = z
  .object({
    inductionVideoLink: z.string(),
    version: z.number(),
    inductionQuestions: z.array(Z_ConfigInductionQuestions),
  })
  .default({
    inductionVideoLink: "",
    version: 0,
    inductionQuestions: [],
  });

export type InductionContent = z.infer<typeof Z_InductionContent>;

const Z_ParkingFields = z.object({
  fieldTechnicalName: z.string(),
  label: z.string(),
  placeHolder: z.string(),
});

export type ParkingField = z.infer<typeof Z_ParkingFields>;

const Z_ParkingDetails = z
  .object({
    parkingAvailable: z.boolean(),
    parkingProviderName: z.string(),
    hasTerms: z.boolean(),
    termsSingleLines: z.array(z.string()),
    captureFields: z.array(Z_ParkingFields),
    availableClassifications: z.array(z.string()).optional(),
  })
  .default({
    parkingAvailable: false,
    parkingProviderName: "",
    hasTerms: false,
    termsSingleLines: [],
    captureFields: [],
    availableClassifications: [],
  });

export type ParkingDetails = z.infer<typeof Z_ParkingDetails>;

const Z_PostInitialRegistrationFlowAvailability = z
  .object({
    isPostInitialRegistration: z.boolean(),
    tokenHasBeenPreviouslyProcessed: z.boolean(),
    tokenPhoneNumber: z.string(),
    completeInduction: z.object({
      available: z.boolean(),
      previousData: z.any(),
    }),
    updateParking: z.object({
      available: z.boolean(),
    }),
    captureCheckInFields: z.object({
      available: z.boolean(),
    }),
  })
  .default({
    isPostInitialRegistration: false,
    tokenHasBeenPreviouslyProcessed: false,
    tokenPhoneNumber: "",
    completeInduction: {
      available: false,
      previousData: false,
    },
    updateParking: {
      available: false,
    },
    captureCheckInFields: { available: false },
  });
export type PostInitialRegistrationFlowAvailability = z.infer<
  typeof Z_PostInitialRegistrationFlowAvailability
>;

const Z_CheckInField = z.object({
  id: z.string(),
  inputSettings: Z_InputSettings,
  label: z.string(),
  title: z.string().optional(),
  staticDropDownListItems: z
    .array(
      z.object({
        dataKey: z.string(),
        text: z.string(),
        itemGroupings: z.array(z.number()).optional(),
      })
    )
    .optional(),
  readOnly: z.boolean(),
  availableForConditionInput: z.boolean(),
  includeInSubmissionIfNotVisible: z.boolean(),
  sliderConfig: z
    .object({
      max: z.number(),
      min: z.number(),
      step: z.number(),
      unit: z.string().optional(),
    })
    .optional(),
});

export type CheckInField = z.infer<typeof Z_CheckInField> & {
  _type: InputTypes;
  _dependencyFields: string[];
  _rootCondition: FieldConditionRoot;
};

const Z_JOIN_OPERATOR = z.enum(["AND", "OR"]);
const Z_COMPARISON_OPERATORS = z.enum([
  "EQ",
  "NE",
  "GT",
  "LT",
  "GE",
  "LE",
  "BT",
  "CONTAINS",
  "NOTCONTAINS",
  "ISPRESENT",
  "ISNOTPRESENT",
]);

interface Filter {
  fieldId?: string;
  joinOperator?: z.infer<typeof Z_JOIN_OPERATOR>;
  operator?: z.infer<typeof Z_COMPARISON_OPERATORS>;
  value1?: string;
  value2?: string;
  filters?: Filter[];
}

const Z_FieldConditionFilter: z.ZodSchema<Filter> = z.lazy(() => {
  return z.object({
    fieldId: z.string().optional(),
    joinOperator: Z_JOIN_OPERATOR.optional(),
    operator: Z_COMPARISON_OPERATORS.optional(),
    value1: z.any().optional(),
    value2: z.any().optional(),
    filters: z.array(Z_FieldConditionFilter).optional(), // see https://github.com/colinhacks/zod#recursive-types
  });
});

export type FieldConditionFilter = z.infer<typeof Z_FieldConditionFilter>;

const Z_FieldConditionRoot = z.object({
  falseState: z.enum(["hidden", "readonly", "editable"]),
  trueState: z.enum(["hidden", "readonly", "editable"]),
  rootFilter: Z_FieldConditionFilter,
});

export type FieldConditionRoot = z.infer<typeof Z_FieldConditionRoot> & {
  rootFilter: FieldConditionFilter;
};

export type FLOW_CONFIG_FIELD_CONDITIONS = {
  [key: string]: FieldConditionRoot;
};

const Z_CheckInFieldDetails = z
  .object({
    /** checkInFieldsAvailable */
    checkInFieldsAvailable: z.boolean(),
    fieldConditions: z.record(Z_FieldConditionRoot),
    captureFields: z.array(Z_CheckInField),
  })
  .default({
    checkInFieldsAvailable: false,
    fieldConditions: {},
    captureFields: [],
  });

export type CheckInFieldDetails = z.infer<typeof Z_CheckInFieldDetails>;

export const entityConfigSchema = z.object({
  sourceDeviceToken: z.string().optional().default(""),
  sourceDeviceTokenUnverifiedProcessing: z.boolean().default(false),
  branding: z
    .object({
      primary: z.string(), // currently we are only interested in the primary color for invites
      // secondary: z.string().default('rgba(17,38,56,1)'),
      // tertiary: z.string().default('rgba(17,38,56,1)'),
      // text: z.string().default('rgba(255,255,255,1)'),
    })
    .default({ primary: "rgba(106,180,100,1)" }),
  logo: z.string().default(""),
  logoSrc: z.string().optional(),
  entityName: z.string().default("Kenai"),
  companyName: z.string().default("Kenai"),
  eventText: z.string().optional().default(""),
  optionalFieldsEnabled: z
    .object({
      email: z.boolean(),
      emailRequired: z.boolean(),
      personalIdentificationNr: z.boolean(),
      company: z.boolean(),
      inductionVideo: z.boolean(),
      inductionQuestions: z.boolean(),
      hostSearch: z.boolean(),
      eventEmailCapture: z.boolean().optional(),
      eventEmailCaptureMandatory: z.boolean().optional(),
      deviceEmailCapture: z.boolean().optional(),
      deviceEmailCaptureMandatory: z.boolean().optional(),
      inviteEmailCapture: z.boolean().optional(),
      inviteEmailCaptureMandatory: z.boolean().optional(),
    })
    .default({
      email: false,
      emailRequired: false,
      personalIdentificationNr: false,
      company: false,
      inductionVideo: false,
      inductionQuestions: false,
      hostSearch: false,
    }),
  inviteOnlyFieldEnablement: z
    .object({
      dietaryRequirements: z.boolean(),
      faceCapture: z.boolean(),
    })
    .default({
      dietaryRequirements: false,
      faceCapture: true,
    }),
  hasBeenLoaded: z.boolean().default(false),
  inductionContent: Z_InductionContent,
  parkingDetails: Z_ParkingDetails,
  checkInFieldDetails: Z_CheckInFieldDetails,
  postInitialRegistrationFlowAvailability:
    Z_PostInitialRegistrationFlowAvailability,
  inviteCreationFields: z
    .object({
      invitePhoneNumber: z.string().optional(),
    })
    .default({}),
  accessPassDeviceDistribution: z
    .object({
      download: z.boolean(),
      email: z.boolean(),
      whatsapp: z.boolean(),
    })
    .default({
      download: false,
      email: false,
      whatsapp: false,
    }),
  whiteLabeled: z.boolean().default(false),
  agreementIdentifier: z.string().optional(),
});

export type REGISTRATION_ENTITY = z.infer<typeof entityConfigSchema>;

export interface AgreementTextPart {
  type: string;
  text: string;
  imageSettings?:
    | {
        imageKey: string;
        width: number;
        height: number;
        loaded: boolean;
      }
    | undefined;
  imagePresignedLink: string;
}

export interface VISITOR_AGREEMENT {
  AgreementVersion: number;
  companyName: string;
  agreementTextParts?: Array<AgreementTextPart>;
  agreementType: string;
}

export interface EventTiming {
  timeSinceEpoch: number;
  timezoneOffset: number;
  timeZone: string;
}

export interface EventDuration {
  days: number;
  hours: number;
  minutes: number;
}
export interface eventMetaData {
  duration: EventDuration;
  eventCreateTimeUTC: number;
  eventDefaultTimezoneString: string;
  eventEndTime: number;
  eventStartTime: number;
  eventTimezoneUTCOffset: number;
  eventTimezoneWindowsString: string;
  location: string;
  summary: string;
}
export interface entityLocationData {
  companyName: string;
  locationFullAddress: string;
  locationShortDescription: string;
  mapLocationCoordinate: string;
  mapLocationTextSearchPart: string;
}

export interface preRegistrationConfig {
  configurationEnabled: boolean;
  distributionEnabled: boolean;
  setupEnabled: boolean;
}

export interface availableIdentities {
  flowIndex: number;
  flowType: number;
  text: string;
}

export interface inviteAutomation {
  setupInviteEmailDistrubutionType: enums.INVITE_EMAIL_DISTRIBUTION_TYPE;
  setupVisitorFlowIndex: number;
  setupSendToExternalOnly: boolean;
  setupDontSendOrganizerConfirmation?: boolean;
}
export interface LocationData {
  EntityHierarchy: string;
  hostLinkingEnabled: boolean;
  entityLocationData: entityLocationData;
  preRegistrationConfig: preRegistrationConfig;
  availableIdentities: Array<availableIdentities>;
  inviteAutomation?: inviteAutomation;
}

export interface SETUP_EVENT_DETAILS {
  EventUid: string;
  Version: number;
  attendees: Array<string>;
  eventMetaData: eventMetaData;
  locationData: Array<LocationData>;
  organizer: string;
  organizerDomain: string;
  status: enums.INVITATION_SETUP_EVENT_STATUS;
  setupOrganizerKey: string | undefined; //new prop
  setupOrganizerNameText: string | undefined; //new prop
  setupHostKey: string;
  setupHostNameText: string;
  setupSecondHostKey: string;
  setupSecondHostNameText: string;
  setupLocationKey: string;
  setupVisitorFlowIndex: number;
  setupAttendeeProcessing: Array<SETUP_ATTENDEE_DETAILS>;
  setupAutomationConfigSource: string;
  setupSendToExternalOnly: boolean;
  setupInviteEmailDistrubutionType: number;
}

export interface SEARCH_SETUP_HOST_RESULT {
  EntityHierarchy: string;
  uniqueAttributeValue: string;
  email: string;
  phone_number: string;
  name: string;
}

export interface SETUP_ATTENDEE_DETAILS {
  EntityHierarchy: string;
  email: string;
  processingSource: string;
  isRegistered: boolean;
  isInternalAttendee: boolean;
  registrationStatusText: string;
  shouldCreateInvite: boolean;
  inviteToggleEnabled: boolean;
  inviteEmailCommand: enums.ATTENDEE_EMAIL_COMMANDS;
  hasExistingInvite: boolean;
  inviteHasBeenMailed: boolean;
  preRegistrationToken: string;
  inviteSendText: string;
  inviteSendPopoverVisible: boolean;
  inviteSendPopoverKey: number;
  inviteSendPopoverChanges: Array<string>;
  inviteSendPopoverQuestion: string;
  inviteSendPopoverSendText: string;
  inviteSendPopoverEmailCommand: enums.ATTENDEE_EMAIL_COMMANDS;
}

export type INVITE_TYPE = "token" | "device" | "event";

export type interfaces = {
  ConfigInductionQuestions: ConfigInductionQuestions;
  InductionContent: InductionContent;
  ParkingDetails: ParkingDetails;
  PostInitialRegistrationFlowAvailability: PostInitialRegistrationFlowAvailability;
  CheckInFieldType: InputTypes;
  CheckInField: CheckInField;
  FIELD_CONDITION_COMPARISON_OPERATORS: keyof typeof enums.FIELD_CONDITION_COMPARISON_OPERATORS;
  FieldConditionFilter: FieldConditionFilter;
  FieldConditionRoot: FieldConditionRoot;
  FLOW_CONFIG_FIELD_CONDITIONS: FLOW_CONFIG_FIELD_CONDITIONS;
  CheckInFieldDetails: CheckInFieldDetails;
  REGISTRATION_ENTITY: REGISTRATION_ENTITY;
  AgreementTextPart: AgreementTextPart;
  VISITOR_AGREEMENT: VISITOR_AGREEMENT;
  EventTiming: EventTiming;
  EventDuration: EventDuration;
  eventMetaData: eventMetaData;
  entityLocationData: entityLocationData;
  preRegistrationConfig: preRegistrationConfig;
  availableIdentities: availableIdentities;
  inviteAutomation: inviteAutomation;
  LocationData: LocationData;
  SETUP_EVENT_DETAILS: SETUP_EVENT_DETAILS;
  SEARCH_SETUP_HOST_RESULT: SEARCH_SETUP_HOST_RESULT;
  SETUP_ATTENDEE_DETAILS: SETUP_ATTENDEE_DETAILS;
  ENTITY_CONFIG: REGISTRATION_ENTITY; // alias as it is more logical usage
  INVITE_TYPE: INVITE_TYPE;
};
